import { useEffect, useState } from 'react'

const makeRequest = (URL, voice, inputText, setFP, setS) => {
  fetch(URL, {
    method: 'POST', // or 'POST', 'PUT', 'DELETE', etc.
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      // Assuming inputText and voice are variables containing the data you want to send
      prompt: inputText,
      voice: voice
    }).toString()
  })
    .then(response => response.text())
    .then(data => {
      console.log(data)
      setTimeout(() => {
        setFP(data)
        setS('Generate!')
      }, 30000)
    })
    .catch(error => console.error('Error:', error))
}

const Content = () => {
  const [chosenVoice, setChosenVoice] = useState('')
  const [inputData, setInputData] = useState('')
  const [filePath, setFilePath] = useState('')
  const [status, setStatus] = useState('Generate')

  const handleVoice = e => {
    e.target.value != '--' && setChosenVoice(e.target.value)
  }
  const handleText = e => {
    setInputData(e.target.value)
  }

  useEffect(() => {
    document.getElementById('aud').load()
  }, [filePath])

  return (
    <div className='content'>
      <div className='voice'>
        <h2>Voice</h2>
        <select name='voice' id='load_voice' onChange={handleVoice}>
          <option value='--'>-- Select an option --</option>
          <option value='obama'>Barack Obama</option>
          <option value='trump'>Donald Trump Jr.</option>
          <option value='biden'>Joe R. Biden</option>
        </select>
      </div>

      <div className='text'>
        <h2>Text</h2>
        <textarea
          name='inputText'
          id='inputText'
          cols='30'
          rows='10'
          onChange={handleText}
          placeholder='Enter text here...'
        ></textarea>
      </div>
      <div>
        <button
          className='generate'
          onClick={() => {
            setStatus('Loading...')
            // console.log(process.env.REACT_APP_URL, chosenVoice, inputData)
            console.log(
              new URLSearchParams({
                id: Math.floor(Math.random() * 1000),
                // Assuming inputText and voice are variables containing the data you want to send
                prompt: inputData,
                voice: chosenVoice
              }).toString()
            )
            makeRequest(
              process.env.REACT_APP_URL,
              chosenVoice,
              inputData,
              setFilePath,
              setStatus
            )

            console.log(filePath)
          }}
        >
          {status}
        </button>
      </div>

      <audio controls id='aud' src={filePath}></audio>
    </div>
  )
}

export default Content
