import Navbar from './Navbar'
import Content from './Content'

function App () {
  return (
    <div className='App'>
      <Navbar />
      <Content />
    </div>
  )
}

export default App
