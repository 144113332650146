const Tooltip = () => {
    return ( 
        <div className="tooltip">
            <ul className="tooltip-menu">
                <li>Home</li>
                <li>Profile</li>
            </ul>
        </div>
     );
}
 
export default Tooltip;