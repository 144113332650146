import logo from './p.png'
import Tooltip from './Tooltip'
import { useState } from 'react'

const Navbar = () => {
  const [tooltipStatus, setTooltipStatus] = useState(false)
  const activateTooltip = () => {
    setTooltipStatus(!tooltipStatus)
    if (tooltipStatus) {
      Tooltip()
    }
  }
  return (
    <div className='nav'>
      <nav className='navbar'>
        <button onClick={activateTooltip}>
          <img src={logo} alt='Company Logo' />
        </button>
      </nav>
      {tooltipStatus && <Tooltip />}
    </div>
  )
}

export default Navbar
